import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const libraries = ["places"];

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

function Store({ store }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA83aUXr9CUIzqf1tmwxD9KDD5Xf-ZqvsE",
    libraries,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "60vh",
  };

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({
    lat: 32.7088,
    lng: -97.1144, // Initial center (Arlington)
    zoom: 10,
  });

  useEffect(() => {
    if (store) {
      setCenter({ lat: store.latitude, lng: store.longitude, zoom: 17 });
    }
  }, [store]);

  const handleLoad = (map) => {
    setMap(map);
  };

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  return (
    <div className="card card-primary mb-0">
      <div className="card-body p-2 p-md-4 p-lg-5 p-xl-5">
        <h3>Your closest CashMax location:</h3>
        <div className="row mb-2">
          {store ? (
            <div className="col-12 col-lg-4 col-md-4 col-sm-4">
              <br /> <strong>Address:</strong>
              <div>{store.address}</div>
              <span>{store.city}</span>,<span>{store.state}</span>{" "}
              <span>{store.zip}</span>
              <br /> <br />
              <div>
                <strong>Manager: {store.manager}</strong>
              </div>
              <div>
                <a href={"tel: " + store.phone}>{store.phone}</a>
              </div>
              <div>
                <a href={"mailto: " + store.email}>
                  <span>{store.email}</span>
                </a>
              </div>
              <div className="mb-4">
                <br />
                <strong>Work hours:</strong>
                <br />
                Monday: <span>{store.hours_mo}</span>
                <br />
                Tuesday: <span>{store.hours_tu}</span>
                <br />
                Wednesday: <span>{store.hours_we}</span>
                <br />
                Thursday: <span>{store.hours_th}</span>
                <br />
                Friday: <span>{store.hours_fr}</span>
                <br />
                Saturday: <span>{store.hours_sa}</span>
                <br />
                Sunday: <span>{store.hours_su}</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="col-12 col-lg-8 col-md-8 col-sm-8">
            <div className="text-end">
              {isLoaded && center ? (
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={center.zoom}
                  center={center}
                  onLoad={handleLoad}
                  options={options}
                >
                  {map && store && (
                    <MarkerF
                      position={{ lat: store.latitude, lng: store.longitude }}
                      icon={{
                        url: require("../results_map_circle.png"),
                        fillColor: "#EB00FF",
                        scale: 10,
                      }}
                      label={{ text: "1", color: "#fff" }}
                    />
                  )}
                </GoogleMap>
              ) : (
                <div>Loading Map...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Store;
