import React, { Component } from 'react';

import logoUrl from '../cashmax-logo-lite.png';

class Logo extends Component {
  render() {
    return (
      <header className="App-header">
        <div align="center">
          <img
            src={logoUrl}
            alt="CashMax"
            className="img-responsive logo-img"
          />
        </div>
      </header>
    );
  }
}
export default Logo;
