import React, { Component } from "react";

class NotFound extends Component {
  state = {};
  render() {
    return <p>The page you are looking for was not found</p>;
  }
}

export default NotFound;
