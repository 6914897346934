import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import "./Main.css";
import Store from "./Store";
import Banner from "./Banner";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";
import Logo from "./Logo";

export default function Main() {
  const { id } = useParams();
  const [store, setStore] = useState(null);
  const [customerPhone, setCustomerPhone] = useState(null);
  const fetchStoreComplete = useRef(false);

  useEffect(() => {
    if (fetchStoreComplete.current === false) {
      fetch(`https://leadsherpapost.com/cashmax/api/v1/getapp/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setStore(data.store);
          setCustomerPhone(data.phone);
          fetchStoreComplete.current = true;
        });
    }
  }, [id, store]);

  return (
    <div className="cashmax">
      <Logo />
      <div className="main-body">
        <div className="container">
          <Banner store={store} customerPhone={customerPhone}></Banner>
          <Store store={store}></Store>
          <FooterMobile></FooterMobile>
          <FooterDesktop></FooterDesktop>
        </div>
      </div>
    </div>
  );
}
