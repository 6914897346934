import React, { Component } from 'react';

import bannerUrl from '../banner.svg';
import seal from '../seal.png';
import godaddy from '../godaddy-siteseal.jpeg';
import consumerAffairs from '../consumer-affairs-badge.png';

import Disclaimer from './Disclaimer';

class FooterDesktop extends Component {
  state = {};
  render() {
    return (
      <div className="footer1">
        <div className="row">
          <div className="col-2">&nbsp;</div>
          <div className="col-8">
            <div className="footer-menu">
              <a
                href="https://www.cashmaxtexas.com/frequently-asked-questions.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Frequently Asked Questions
              </a>

              <a
                href="https://www.cashmaxtexas.com/customer-reviews-testimonials.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Customer Testimonials
              </a>
              <a
                href="https://www.cashmaxtexas.com/cash-advance-installment-loan.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Title Loan or Advanced Cash
              </a>
              <br />
              <a
                href="https://www.cashmaxtexas.com/locations.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Find a Location Near You
              </a>
              <a
                href="https://www.cashmaxtexas.com/frequently-asked-questions/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                Privacy Information
              </a>
            </div>
            <div className="disclaimer-text">
              <Disclaimer></Disclaimer>
            </div>
            <div className="text-center">
              <span className="me-5">
                <a
                  href="https://seal.godaddy.com/verifySeal?sealID=nt3FPyKjRR7CWWlgAVr5KsydSMtaDZcOACZ2olS97ZriwtMKcvNNoHq3WuTg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={godaddy}
                    alt="SSL site seal - click to verify"
                  />
                </a>
              </span>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.consumeraffairs.com"
                >
                  <img alt="ConsumerAffair" src={consumerAffairs} />
                </a>
              </span>
            </div>
            <div>
              <div className="footer1-text">
                <div>
                  &copy; Copyright {new Date().getFullYear()} CashMax
                  <sup>&reg;</sup>. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div id="ola" alt="OLA Seal">
              <a
                href="http://onlinelendersalliance.org/look-for-the-ola-seal"
                target="_blank"
                rel="noopener noreferrer"
                title="Opens in new window"
              >
                <img
                  src={bannerUrl}
                  alt="Online Lenders Alliance - click to verify"
                  className="ola-banner"
                />
                <img alt="OLA Seal" src={seal} className="ola" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterDesktop;
