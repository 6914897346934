import React, { Component } from "react";

class Disclaimer extends Component {
  render() {
    return (
      <div>
        <p>
          Loans subject to lender approval. Loans are provided by an
          unaffiliated third-party lender. Not all customers will qualify for
          loan or qualify for maximum loan amount. Loan terms and availability
          may vary by location. 3rd party lender rates apply. Approval subject
          to vehicle value. Visit or call the store for details.
        </p>
      </div>
    );
  }
}

export default Disclaimer;
