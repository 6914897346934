import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import "./Main.css";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";
import Logo from "./Logo";

export default function Main() {
  const { id } = useParams();
  const [status, setStatus] = useState(null);
  const fetchStoreComplete = useRef(false);

  useEffect(() => {
    if (fetchStoreComplete.current === false) {
      fetch(`https://leadsherpapost.com/cashmax/api/v1/unsubscribe/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setStatus(data.status);
          fetchStoreComplete.current = true;
        });
    }
  }, [id, status]);

  return (
    <div className="cashmax">
      <Logo />
      <div className="main-body">
        <div className="container">
          <div className="card card-primary mb-0">
            <div className="card-body p-5 unsubscribe-panel">
              {status ? (
                <div>
                  <h2>Success!</h2>
                  <h4>Your email {id} has been removed from mailing list.</h4>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <FooterMobile></FooterMobile>
          <FooterDesktop></FooterDesktop>
        </div>
      </div>
    </div>
  );
}
