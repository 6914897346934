import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Main";
import Unsubscribe from "./Unsubscribe";
import NotFound from "./NotFound";

class PageRouter extends Component {
  state = {};
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/application/:id" element={<Main />} />
          <Route path="/unsubscribe/:id" element={<Unsubscribe />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default PageRouter;
