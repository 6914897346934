import React, { Component } from "react";

import seal from "../seal.png";
import godaddy from "../godaddy-siteseal.jpeg";
import consumerAffairs from "../consumer-affairs-badge.png";

import Disclaimer from "./Disclaimer";

class FooterMobile extends Component {
  state = {};
  render() {
    return (
      <div className="footer2">
        <div className="footer-seal">
          <img
            src={seal}
            alt="OLA Seal"
            title="OLA Seal"
            width="100"
            height="100"
          />
        </div>
        <div className="text-center pt-1 pb-1">
          <a href="tel:1-888-485-3629" className="footerPhoneNumberText">
            Questions? Call 1-888-485-3629
          </a>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="pt-2 pe-2 pb-0 ps-4">
              <a
                href="https://www.cashmaxtexas.com/frequently-asked-questions.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Frequently Asked Questions
              </a>
            </div>
            <div className="pt-2 pe-2 pb-0 ps-4">
              <a
                href="https://www.cashmaxtexas.com/customer-reviews-testimonials.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Customer Testimonials
              </a>
            </div>
            <div className="pt-2 pe-2 pb-0 ps-4">
              <a
                href="https://www.cashmaxtexas.com/cash-advance-installment-loan.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Title Loan or Advanced Cash
              </a>
            </div>
          </div>
          <div className="col-6">
            <div className="pt-2 pe-2 pb-0 ps-4">
              <a
                href="https://www.cashmaxtexas.com/locations.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Find a Location Near You
              </a>
            </div>
            <div className="pt-2 pe-2 pb-0 ps-4">
              <a
                href="https://www.cashmaxtexas.com/frequently-asked-questions/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Information
              </a>
            </div>
          </div>
        </div>
        <div className="footer2-text">
          <Disclaimer></Disclaimer>
        </div>
        <div>
          <div className="pt-5 text-center">
            <span className="me-5">
              {" "}
              <a
                href="https://seal.godaddy.com/verifySeal?sealID=nt3FPyKjRR7CWWlgAVr5KsydSMtaDZcOACZ2olS97ZriwtMKcvNNoHq3WuTg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={godaddy} alt="SSL site seal - click to verify" />
              </a>
            </span>{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.consumeraffairs.com"
              >
                <img alt="ConsumerAffairs" src={consumerAffairs} />
              </a>
            </span>
          </div>
        </div>
        <div className="footer-text">
          <div>
            &copy; Copyright {new Date().getFullYear()} CashMax
            <sup>&reg;</sup>. All Rights Reserved.
          </div>
        </div>
      </div>
    );
  }
}

export default FooterMobile;
